<template>
  <div class="orderconfirm-wrap">
    <div class="bread-wrap">
      <span @click="navigateback"
            class="backbtn"><i class="el-icon-arrow-left"></i></span>
      <span class="bread-title">订单确认</span>
    </div>
    <div class="order-wrap">
      <div class="order-left">
        <p class="title-wrap">
          <i class="iconfont icon-jiaofeizhangdan titleicon"></i><span class="title">订单信息</span>
        </p>
        <div class="ff-detail">
          <span class="dtcontent">充值金额</span><span class="dttext">{{ Total }} 元</span>
        </div>
        <div class="ff-detail">
          <span class="dtcontent">充值方式</span><span class="dttext">{{ PayType == 'alipay_page' ? '支付宝支付': '微信支付' }}</span>
        </div>

        <div class="order-qr">
          <div class="qrcode-wrap">
            <div class="qrcode"
                 id="qrcodeImg"></div>
          </div>
          <div class="scan">请使用{{ PayType == 'alipay_page' ? '支付宝': '微信' }}扫码支付</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatdate from "@/utils/format";
import navigation from "@/utils/navigation";
import QRCode from "qrcodejs2";
import Big from "big.js";

export default {
  name: "orderconfirm",
  data () {
    const date = new Date();
    return {
      TradeNo: "",
      Total: 0,
      PayType: '',
    };
  },
  mounted () {
    // console.log("pay params: " + JSON.stringify(this.$route.params))
    var payload = this.$route.params.payload;
    this.Total = payload.Total
    this.PayType = payload.PayType
    const total = new Big(this.Total).times(100).toNumber()
    //创建订单
    this.$store
      .dispatch("pay/createrechargeorder", { Total: total })
      .then((res) => {
        if (res.RetCode == 0) {
          this.TradeNo = res.TradeNo;
          if (!this.amountPay) {
            this.getqrcode();
          }
        } else {
          this.$message.error(res.Message);
        }
      });

  },
  beforeDestroy () {
    clearInterval(this.timmer);
  },
  methods: {
    qrcode (url) {
      return new QRCode("qrcodeImg", {
        width: 230,
        height: 230,
        text: url,
        colorDark: "#000",
        colorLight: "#fff",
      });
    },
    navigateback () {
      navigation.back();
    },
    getpayconfirm () {
      this.timmer = setInterval(() => {
        this.$store
          .dispatch("pay/getpayconfirm", {
            TradeNo: this.TradeNo,
            PayType: "wechatpay_native",
            OrderType: "Recharge",
          })
          .then((res) => {
            if (res.RetCode == 0 && res.Status == "Paid") {
              clearInterval(this.timmer);
              this.$message.success("支付成功！");
              if (this.$router.getRoutes().length > 2) {
                this.$router.go(-2)
              } else {
                navigation.redirectTo({ url: "personalcenter" })
              }
            } else if (res.RetCode !== 0) {
              clearInterval(this.timmer);
            }
          });
      }, 1000);
    },
    getqrcode () {
      const payload = {
        TradeNo: this.TradeNo,
        PayType: this.PayType,
        OrderType: "Recharge",
      };
      this.$store.dispatch("pay/getpaymentparams", { ...payload }).then((res) => {
        if (res.RetCode == 0) {
          this.qrcode(res.PayReq);
          this.getpayconfirm();
          // window.location.replace(res.PayReq)
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>